.h-wrapper {
    /* background: var(--black); */
    color: white;
    z-index: 99;
}

.h-container {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: var(--secondary);
    justify-content: space-between;
}

.h-menu {
    gap: 1.5rem;
}

.h-menu>*:hover {
    cursor: pointer;
}

.menu-icon {
    display: none;
}


ul li a{
    @apply rounded py-[7px] px-[13px] text-[17px]
    hover:bg-slate-500 transition-all duration-300
}



#check:checked ~ ul{
    left: 0;
}

@media (max-width: 768px) {
    /* .menu-icon {
        display: block;
    }

    .h-menu {
        color: var(--black);
        position: absolute;
        gap: 2rem;
        font-weight: 500;
        flex-direction: column;
        right: 4rem;
        top: 3rem;
        background: white;
        display: flex;
        border-radius: 10px;
        transition: all 200ms ease;
        align-items: flex-start;
        padding: 3rem;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
        z-index: 99;

    } */
}